<template>
  <div>
    <v-dialog v-model="dialog" persistent scrollable width="600">

      <v-card :disabled="saveLoading" :loading="saveLoading">

        <v-card-title>
          {{ title }}
          <v-spacer/>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-10">

          <v-text-field v-model="form.code"
                        :error-messages="errors.code"
                        dense
                        label="Code *"
                        outlined>
          </v-text-field>

          <v-text-field v-model="form.name"
                        :error-messages="errors.name"
                        dense
                        label="Nom *"
                        outlined>
          </v-text-field>

          <v-select v-model="form.brand_id"
                    :error-messages="errors.brand_id"
                    :items="brands"
                    dense
                    item-text="name"
                    item-value="id"
                    label="Marque *"
                    outlined>
          </v-select>

          <v-file-input ref="image"
                        :error-messages="errors.image"
                        accept="image/*"
                        chips
                        counter
                        dense
                        label="Image"
                        outlined
                        show-size
                        small-chips
                        @change="uploadImage"
                        @click:clear="resetImage">
          </v-file-input>

          <v-textarea v-model="form.description"
                      :error-messages="errors.description"
                      dense
                      rows="3"
                      hide-details="auto"
                      label="Description"
                      outlined>
          </v-textarea>

        </v-card-text>

        <v-divider/>

        <v-card-actions class="grey lighten-4">
          <v-spacer/>
          <v-btn :loading="saveLoading"
                 color="secondary"
                 depressed
                 @click="save">
            <v-icon left>mdi-content-save</v-icon>
            Enregistrer
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
    name: "ProductForm",
    props: ['brands'],
    data() {
        return {
            title: '',
            dialog: false,
            saveLoading: false,
            errors: {},
            form: {}
        }
    },
    methods: {
        uploadImage(file) {
            if (file) {
                const reader = new FileReader()
                reader.onloadend = () => {
                    this.form.image = reader.result
                }
                reader.readAsDataURL(file)
            }
        },
        open(item) {
            this.resetImage()
            this.errors = {}
            this.dialog = true
            this.form = {
                id: item ? item.id : '',
                code: item ? item.code : '',
                name: item ? item.name : '',
                description: item ? item.description : '',
                image: '',
                brand_id: item ? item.brand_id : '',
            }
            this.title = item ? 'Modifier un produit' : 'Ajouter un produit'
        },
        save() {
            this.saveLoading = true
            this.errors = {}

            HTTP({
                url: this.form.id ? '/products/' + this.form.id : '/products',
                method: this.form.id ? 'put' : 'post',
                data: this.form,
            }).then(() => {
                if (this.form.id) {
                    this.dialog = false
                }
                this.$emit('refresh')
                this.$successMessage = 'Cet élément a ete enregistré avec succes'
                this.saveLoading = false
                this.resetImage()

                this.form = {
                    id: '',
                    code: '',
                    name: '',
                    description: '',
                    image: '',
                    brand_id: '',
                }

            }).catch(err => {
                this.saveLoading = false
                this.errors = err.response.data.errors
                console.log(err)
            })
        },
        resetImage() {
            if (this.$refs.image) {
                this.$refs.image.reset()
            }
            this.form.image = ''
        }
    }
}
</script>

<style scoped>

</style>