<template>
  <div>
    <v-container fluid>
      <v-card class="shadow rounded-lg">

        <Overlay :overlay="overlay"/>

        <v-row align="center" class="pa-0 ma-0 py-1 mx-1">
          <v-col cols="12" lg="3">
            <v-text-field v-model.trim="search"
                          class="rounded-input"
                          clearable
                          dense
                          hide-details
                          outlined
                          placeholder="Recherche..." prepend-inner-icon="mdi-magnify"
                          single-line/>
          </v-col>
          <v-col cols="12" lg="3">
            <v-select v-model="filter.brand_id"
                      :items="brands"
                      prepend-inner-icon="mdi-filter-outline"
                      :loading="brandLoading"
                      clearable
                      dense
                      hide-details
                      item-text="name"
                      item-value="id"
                      outlined
                      placeholder="Marque"
                      single-line/>
          </v-col>
          <v-col class="text-end" cols="12" lg="6">
            <v-btn class="mr-2" color="primary" text @click="$refs.brandList.open()">
              <v-icon left>mdi-sort-reverse-variant</v-icon>
              Marques
            </v-btn>

            <v-btn color="primary" class="mr-2" depressed @click="$refs.productForm.open()">
              <v-icon left>mdi-plus</v-icon>
              Produit
            </v-btn>

            <v-btn color="primary" icon @click="getProducts">
              <v-icon>mdi-table-refresh</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-divider/>

        <v-card-text>

          <v-skeleton-loader v-if="isLoading" type="table"/>

          <v-data-table v-if="products.length && !isLoading"
                        :headers="headers"
                        :items="filteredItems"
                        :items-per-page="10"
                        :search="search"
                        class="table-border rounded-lg"
          >
            <template v-slot:item.actions="{ item,index }">
              <div class="d-flex">
                <v-btn color="red" icon disabled @click="deleteProduct(item.id,index)">
                  <v-icon size="20">mdi-trash-can</v-icon>
                </v-btn>
                <v-btn color="" icon @click="$refs.productForm.open(item)">
                  <v-icon size="20">mdi-pencil-outline</v-icon>
                </v-btn>
              </div>
            </template>

            <template v-slot:item.image="{ item }">

              <v-img v-if="item.image"
                     :src="$baseUrl + item.image"
                     class="zoom-pointer rounded"
                     min-height="35"
                     max-height="35"
                     min-width="35"
                     max-width="35"
                     @click="$refs.lightbox.open(item.image)"/>

              <v-img v-else
                     :src="require('@/assets/no-product.png')"
                     class="rounded"
                     height="35"
                     width="35"/>

            </template>

            <template v-slot:item.is_competitor="{ item }">
              <v-icon v-if="item.brand.is_competitor" color="success">mdi-check-circle</v-icon>
              <v-icon v-else color="red">mdi-close-circle</v-icon>
            </template>

          </v-data-table>

          <NoResult v-if="!products.length && !isLoading"/>

          <Lightbox ref="lightbox"/>
          <ConfirmDialog ref="confirmDialog"/>
          <BrandList ref="brandList" @refresh="getBrands"/>
          <ProductForm ref="productForm" :brands="brands" @refresh="getProducts"/>

        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import BrandList from "@/views/products/components/BrandList.vue";
import ProductForm from "@/views/products/components/ProductForm.vue";

export default {
    components: {ProductForm, BrandList},
    data() {
        return {
            brandLoading: false,
            brands: [],
            filter: {
                brand_id: ''
            },
            products: [],
            overlay: false,
            isLoading: false,
            search: null,
        }
    },

    methods: {
        getProducts() {
            this.isLoading = true
            HTTP.get('products').then(res => {
                this.products = res.data.data
                this.isLoading = false
            }).catch(err => {
                this.isLoading = false
                console.log(err)
            })
        },
        async deleteProduct(id, index) {
            if (await this.$refs.confirmDialog.open()) {
                this.overlay = true
                HTTP.delete('products/' + id).then(() => {
                    this.products.splice(index, 1)
                    this.overlay = false
                    this.$successMessage = 'Cet élément a été supprimé avec succès.'
                }).catch(err => {
                    this.overlay = false
                    console.log(err)
                })
            }
        },
        getBrands() {
            this.brandLoading = true
            HTTP.get('brands').then(res => {
                this.brands = res.data.data
                this.brandLoading = false
            }).catch(err => {
                this.brandLoading = false
                console.log(err)
            })
        },
    },
    created() {
        this.getBrands()
        this.getProducts()
    },
    computed: {
        headers() {
            return [
                {text: 'Image', value: 'image'},
                {text: 'Code', value: 'code'},
                {text: 'Nom', value: 'name'},
                {text: 'Marque', value: 'brand.name'},
                {text: 'Marque concurrent', value: 'is_competitor'},
                {text: 'Actions', value: 'actions', sortable: false},
            ]
        },
        filteredItems() {
            return this.products.filter(item => {
                if (this.filter.brand_id) {
                    return item.brand_id === this.filter.brand_id
                } else {
                    return item
                }
            })
        }
    }
}
</script>

<style scoped>

</style>