<template>
  <div>
    <v-dialog v-model="dialog" persistent scrollable width="800">

      <v-card :disabled="saveLoading" :loading="saveLoading">

        <Overlay :overlay="overlay"/>

        <v-card-title>
          Marques
          <v-spacer/>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <div class="px-10 pt-10">

          <div class="d-flex">

            <v-text-field v-model="form.name"
                          :error-messages="errors.name"
                          dense
                          label="Nom de marque *"
            >
            </v-text-field>

            <v-text-field v-model="form.description"
                          :error-messages="errors.description"
                          dense
                          label="Description"
            >
            </v-text-field>

            <v-btn :loading="saveLoading"
                   class="mx-1"
                   color="secondary"
                   depressed
                   @click="save">
              <v-icon left>mdi-content-save</v-icon>
             Enregistrer
            </v-btn>

            <v-btn v-if="form.id"
                   class="v-btn--active"
                   color="red"
                   text
                   @click="resetForm">
              Annuler
            </v-btn>

          </div>

          <v-checkbox v-model="form.is_competitor"
                      class="ma-0 pa-0"
                      color="secondary"
                      hide-details
                      label="Marque concurrente"></v-checkbox>

        </div>

        <v-card-text class="px-8 pt-8">

          <div class="table-border rounded">
            <v-card-title>
              <div>
                <v-text-field v-model="search"
                              :style="{width : '250px'}"
                              prepend-inner-icon="mdi-magnify"
                              clearable
                              dense
                              class="rounded-input"
                              hide-details
                              outlined placeholder="Recherche..."
                              single-line/>
              </div>

              <v-spacer/>

              <v-btn color="primary" icon @click="getBrands">
                <v-icon>mdi-table-refresh</v-icon>
              </v-btn>

            </v-card-title>

            <v-divider/>

            <v-skeleton-loader v-if="isLoading" type="table"/>

            <v-data-table v-else
                          :headers="headers"
                          :items="brands"
                          :items-per-page="10"
                          :search="search"
            >

              <template v-slot:item.actions="{ item,index }">
                <div class="d-flex">
                  <v-btn color="red" disabled icon @click="deleteBrand(item.id,index)">
                    <v-icon size="20">mdi-trash-can</v-icon>
                  </v-btn>
                  <v-btn color="" icon @click="editBrand(item)">
                    <v-icon size="20">mdi-pencil-outline</v-icon>
                  </v-btn>
                </div>
              </template>

              <template v-slot:item.is_competitor="{ item }">
                <v-icon v-if="item.is_competitor" color="success">mdi-check-circle</v-icon>
                <v-icon v-else color="red">mdi-close-circle</v-icon>
              </template>

            </v-data-table>
          </div>

        </v-card-text>

      </v-card>
    </v-dialog>

    <ConfirmDialog ref="confirmDialog"/>

  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
    data() {
        return {
            dialog: false,
            overlay: false,
            isLoading: false,
            search: '',
            saveLoading: false,
            errors: {},
            form: {
                id: '',
                name: '',
                description: '',
                is_competitor: false,
            },
            brands: []
        }
    },
    methods: {
        open() {
            this.errors = {}
            this.dialog = true
            this.getBrands()
        },
        editBrand(item) {
            this.form = {
                id: item.id,
                name: item.name,
                description: item.description,
                is_competitor: item.is_competitor,
            }
        },
        resetForm() {
            this.form = {
                id: '',
                name: '',
                description: '',
                is_competitor: false,
            }
        },
        save() {
            this.saveLoading = true
            this.errors = {}

            HTTP({
                url: this.form.id ? '/brands/' + this.form.id : '/brands',
                method: this.form.id ? 'put' : 'post',
                data: this.form,
            }).then(() => {
                this.$emit('refresh')
                this.getBrands()
                this.$successMessage = 'Cet élément a ete enregistré avec succes'
                this.saveLoading = false
                this.resetForm()
            }).catch(err => {
                this.saveLoading = false
                this.errors = err.response.data.errors
                console.log(err)
            })
        },
        getBrands() {
            this.isLoading = true
            HTTP.get('brands').then(res => {
                this.brands = res.data.data
                this.isLoading = false
            }).catch(err => {
                this.isLoading = false
                console.log(err)
            })
        },
        async deleteBrand(id, index) {
            if (await this.$refs.confirmDialog.open()) {
                this.overlay = true
                HTTP.delete('brands/' + id).then(() => {
                    this.brands.splice(index, 1)
                    this.overlay = false
                    this.$successMessage = 'Cet élément a été supprimé avec succès.'
                }).catch(err => {
                    this.overlay = false
                    console.log(err)
                })
            }
        },
    },
    computed: {
        headers() {
            return [
                {text: 'Nom', value: 'name'},
                {text: 'Description', value: 'description'},
                {text: 'Concurrent', value: 'is_competitor'},
                {text: 'Actions', value: 'actions', sortable: false},
            ]
        },
    }
}
</script>

<style scoped>

</style>
<script setup>
</script>